import React from 'react';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import './styles.css'
import { map } from 'lodash';

const TableWinners = ({ data}) => {
  const {t} = useTranslation()
  const classes = useStyles();

  const renderTableDesktop = () => {
    return (
      <div className={classes.winners__table__wrapper}>
          {map(data, (cell, index)=> {
            return <div key={index} className={classes.winners__table}>
              <div className={classes.table_container_numers}> {cell.date}</div>
              {map(cell.singlePrizeList, (resultRow,indexNumber)=> {
                return <div key={indexNumber} className={classes.table_container_numers}> {resultRow.result} </div>
              })}
            </div>
          })}
      </div>
    )
  };

  const renderTitle = () => {
    return (
      <div className={classes.title__containner}>
        <div className={classes.winner_title}>
            <span>
              {t('Date')}
            </span>
        </div>
        <div className={classes.winner_title}>
            <span>
              {t('FirstResult')}
            </span>
        </div>
        <div className={classes.winner_title}>
            <span>
            {t('SecondResult')}
            </span>
        </div>
        <div className={classes.winner_title}>
            <span>
            {t('ThirdResult')}
            </span>
        </div>
      </div>
    )
  }

  return <div className={classes.winners}>
    {renderTitle()}
    {renderTableDesktop()}
  </div>
};

export default TableWinners;
