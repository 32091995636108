import { makeStyles } from "@material-ui/core/styles";

import {font } from "config/styles";

export const useStyles = makeStyles((theme) => ({
  winners: {
    width: '100%',
    minHeight: '100%',
    // backgroundColor: '#eff1f0',
  },

  title__containner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '5px 0 5px 0',
    minHeight: '50px',
    backgroundColor: '#1ab08d',
    [theme.breakpoints.down('xs')]: {
      minHeight: '40px',
    }
  },

  winner_title: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: '10px',
    flex: '1',
    '& span': {
      margin: '0px',
      color: 'white',
      fontSize: '20px',
      [theme.breakpoints.down('sm')]: {
        // fontSize: font.nomalSize__mobile,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '3.2vw',
      },
      [theme.breakpoints.down(360)]: {
        fontSize: '13px',
      }
    }
  },

  image_title: {
    height: '12px',
    width: 'auto',
    margin:'2px 2px 0px 2px',
    [theme.breakpoints.down('sm')]: {
      margin:'2px 2px 0px 2px',
    }
  },


  winners__table__wrapper: {
    display: 'flex',
    width: '100%',
    alignItems: 'flex-start',
    backgroundColor: '#eff1f0',
    // padding: '10px 0 10px 0',
    borderRadius: '5px',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0px',
      fontSize: font.nomalSize__mobile,
    },
  },

  winners__table: {
    display: 'flex',
    width: '100%',
    minHeight: '40px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'centrer',
    borderBottom: '1px solid #dcdedd',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexWrap: 'wrap',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      flexWrap: 'wrap',
      minHeight: '35px',
    },
  },

  table_container_numers: {
    display: 'flex',
    alignItems:'center',
    justifyContent: 'flex-start',
    marginTop:'5px',
    marginBottom:'5px',
    color: 'black',
    paddingLeft: '10px',
    width: '25%',
    fontSize: '18px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '3vw',
    },
    [theme.breakpoints.down(360)]: {
      fontSize: '10px',
    }
  },
  table_number: {
    display: 'flex',
    alignItems:'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: '38px',
    height: '38px',
    fontSize: '28px',
    padding: '1px',
    margin: '5px',
    color: '#000',
    backgroundColor: '#fff',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
      width: '30px',
      height: '30px',
      margin: '2px',
    },
    [theme.breakpoints.down('xs')]: {
      // borderRadius: '2px',
      fontSize: '4.5vw',
      width: '6vw',
      height: '6vw',
      margin: '1px',
    }
  }
}));
