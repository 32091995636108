import { makeStyles } from "@material-ui/core/styles";
import { font } from "config/styles";

export const useStyles = makeStyles((theme) => ({
  container_page: {
    position: 'relative',
    display: 'flex',
    flex: '1',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    // minHeight: '100%',
    height: 'inherit',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: color.mainBackground
    }
  },
  multi_prize_container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    marginBottom: '15px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
    '& span': {
      padding: '0 0 0 15px',
      [theme.breakpoints.down('sm')]: {
        padding: '0 0 0 10px',
        fontSize: font.nomalSize__mobile,
      },
    },
    '& :nth-child(1)': {
      color: '#fff'  
    },
    '& :nth-child(2)': {
      color: '#1ab50d'  
    },

    '& :nth-child(3)': {
      color: '#ffbb02'
    },
  },
  menus: {
    display: 'flex',
    position: 'relative',
    // justifyContent: 'space-around',
    flexFlow: 'column',
    alignItems: 'center',
    width: '100%',
    marginRight: '0',
    flexDirection: 'row',
    height: '35px',
    marginBottom: '10px',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#e1e1df',
      width: '100%',
      marginRight: '0',
      flexDirection: 'row',
      height: '35px', 
      marginBottom: '5px',
      marginTop: '5px'
    },
    [theme.breakpoints.down('xs')]: {
      backgroundColor: '#e1e1df',
      marginBottom: '5px',
      marginTop: '5px',
      marginRight: '0',
      width: '100%',
      height: '30px', 
    }
  },

  content: {
    display: 'inline-block',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      
    },
    [theme.breakpoints.down('xs')]: {
      // width: 'calc(100% -120px)',
    }
  },

  menuItem : {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: font.nomalSize,
    height: '100%',
    padding: '0px 10px 0px 10px',
    cursor: 'pointer',
    zIndex: '1',
    color: 'black',
    borderRadius: '5px',
    marginRight: '10px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      padding: '5px 10px 5px 10px',
      fontSize: font.nomalSize__mobile,
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      padding: '10px 2px 10px 2px',
      fontSize: '2.5vw',
    }
  },
  menuItemSelected: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: font.nomalSize,
    height: '100%',
    cursor: 'pointer',
    zIndex: '1',
    color: 'black',
    borderRadius: '20px',
    padding: '0px 10px 0px 10px',
    backgroundColor: '#ff9933',
    marginRight: '10px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0px',
      justifyContent: 'center',
      padding: '0px 10px 0px 10px',
      fontSize: font.nomalSize__mobile,
      backgroundColor: '#ff9933',
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: '0px',
      justifyContent: 'center',
      padding: '10px 2px 10px 2px',
      fontSize: '2.5vw',
      backgroundColor: '#ff9933',
    }    
  },
  dialog_result: {
    display: 'flex',
    position: 'absolute',
    right: '0',
    fontSize: '30px',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection:'column'
  },
  img_coin_effect: {
    transform: 'translateX(-50%)',
    position: 'absolute',
    bottom: '-10%',
    left: '50%',
    width: '140%',
    zIndex: '0',
    pointerEvents: 'none',
    [theme.breakpoints.down('sm')]: {
      width: '120%',
      top: '40%',
      position: 'fixed',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
      width: '150%',
      top: '20%',
      position: 'fixed',
    },
  },
}));
