const india = {
  translations: {
    "ContactUs": "Contact Us",
    'SUBMIT': 'SUBMIT',
    "Date": "Date",
    "Results": 'Results',
    'DrawNo': 'Draw No.',
    'Sun': 'Sunday',
    'Mon': 'Monday',
    'Tue': 'Tuesday',
    'Wed': 'Wednesday',
    'Thu': 'Thursday',
    'Fri': 'Friday',
    'Sat': 'Saturday',
    'No': 'No',
    'Day': 'Day',
    'Result': 'Result',
    'Prize': 'Prize',
    'DrawResults': 'Draw Results',
    'At': 'at',
    'CompanyName': 'MAGNUM45',
    'WinNumbers': 'WINNING NUMBER',
    'WillBeHelp': 'WILL BE HELD',
    'NextDraw': 'NEXT DRAW',    
    'FirstResult': '1st',
    'SecondResult': '2nd',
    'ThirdResult': '3rd',
    'Jan': 'January',
    'Feb': 'February',
    'Mar': 'March',
    'Apr': 'April',
    'May':  'May',
    'Jun': 'June',
    'Jul': 'July',
    'Aug': 'August',
    'Sep': 'September',
    'Oct': 'October',
    'Nov': 'November',
    'Dec': 'December',
    'ContactUsContent':	'Any comments or questions of our services,\nPlease mail to: cs@totomagnum45.com',
    'FooterContent': 'This Website Is Compatible With Apple Safari 4, Google Chrome, Microsoft Internet Explorer 7, 8, & 9, Mozilla Firefox 3+ And Opera 10+. Our Time Is Based On GMT +8',
    'Live': 'Live'
  }
};

export default india;
