import { makeStyles } from "@material-ui/core/styles";
import {color, layout } from "config/styles";
import headerBg from 'assets/img/home/header_bg.png';

export const useStyles = makeStyles((theme) => ({
  containner: {
    backgroundPosition: 'center top',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'scroll',
    backgroundSize: "auto 100%",
    animation: 'fadeIn ease-in .5s',
    transition: 'all .5s ease-in-out',
    position: 'relative',
    backgroundImage: `url(${headerBg})`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    width: '100%',
    minHeight: '200px',
    maxHeight: '35%',
    [theme.breakpoints.down('sm')]: {
      minHeight: layout.headerHeighBg__mobile,
      '& ball__left': {
        display: 'flex'
      }
    },
  },
  
  img_logo: {
    position: 'absolute',
    left: '10px',
    bottom: '10px',
    width: '30%',
    [theme.breakpoints.down('sm')]: {
      // transform: 'translateX(-50%)',
      width: '40%',
      left: '10px',
      bottom: '10px',
    },
  },

  img_lottery: {
    position: 'absolute',
    right: '10px',
    bottom: '-30px',
    height: '130%',
    [theme.breakpoints.down('sm')]: {
      // transform: 'translateX(-50%)',
      right: '10px',
      bottom: '10px',
      height: '80%',
    },
  },
  menu__item_language: {
    display: 'flex',
    position: 'absolute',
    top: '10px',
    left: '10px',
    justifyContent: 'center',
    alignItems: 'flex-start',
    // marginRight: '45px',
    color: color.black,
    [theme.breakpoints.down('sm')]: {
      marginRight: '0px',
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: '0px',
    }
  },
}));
