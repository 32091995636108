import React, { useState } from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Background, RouterContext } from 'context/RouterContext';

import Home from 'pages/Home';
import Results from 'pages/Results';
import AboutUs from 'pages/AboutUs';
import Menu from './shared/Menu';
import Footer from './shared/Footer';
import LogoCompany from './pages/Home/LogoCompany';
import { StyledContainer } from "config/styles";
import { useStyles } from './AppStyles';
import { Hidden } from '@material-ui/core';
import MetaData from './services/MetaData';

const history = createBrowserHistory();
const Routes = () => {
	const classes = useStyles();
	
	const [background, setBackground] = useState(Background.home);
	return (
		<RouterContext.Provider value={{ background: background, setBackground: setBackground }}>
			<Router history={history}>
				<MetaData />
				<div
					className={classes.Content}
					style={{ backgroundImage: `url(${background.src})` }}>
					<StyledContainer>
						<LogoCompany />
						<Hidden smDown>
							<Menu />
						</Hidden>
						<Hidden mdUp>
							<Menu />
						</Hidden>
						<Switch>
							<Route exact path='/' name='Live' component={Home} />
							<Route exact path='/result' name='result' component={Results} />
							<Route exact path='/contact-us' name='Contact Us' component={AboutUs} />
							<Route path="*">
								<Redirect to="/" />
							</Route>
						</Switch>
						<Footer />
					</StyledContainer>
					
				</div>
			</Router>
		</RouterContext.Provider>
	);
}

export default Routes;
