import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthContext } from 'context/AuthContext'
import { useStyles } from "./styles"

const Menu = () => {
	const classes = useStyles();
	const authContext = useContext(AuthContext);
	const { t } = useTranslation();
	const handleClick = () => {
		if (authContext.homeFunc) {
			authContext.homeFunc();
		}
	}
	return <div className={classes.menu__list}>
		<div className={classes.menu__routers}>
			<div className={classes.menu__item}>
				<NavLink exact onClick={handleClick} className={classes.menu__item_link} activeClassName={classes.menu__item_selected} to={'/'}>{t('Live')}
					<div className='after'/>
				</NavLink>
			</div>

			<div className={classes.menu__item}>
				<NavLink exact className={classes.menu__item_link} activeClassName={classes.menu__item_selected} to={'/result'}>{t('Results')}
					<div className='after'/>
				</NavLink>
			</div>

			<div className={classes.menu__item}>
				<NavLink exact className={classes.menu__item_link} activeClassName={classes.menu__item_selected} to={'/contact-us'}>{t('ContactUs')}
					<div className='after'/>
				</NavLink>
			</div>
		</div>
	</div>
};

export default Menu
