import React from "react";
import { useTranslation } from "react-i18next";
import { StyledContainerFooter } from "config/styles";
import { useStyles } from "./styles";

const Footer = () => {
	const { t } = useTranslation()
	const classes = useStyles();
	
	return <div className={classes.footer}>
		<div className={classes.fotter_containner_top}>
			<StyledContainerFooter>
				<div className={classes.footerClass}>
					<div className={classes.footerRight}>
						<span className={classes.footerDeposit}>{t('FooterContent')}</span>
					</div>
				</div>
			</StyledContainerFooter>
		</div>
	</div>
};

export default Footer
