import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from 'context/AuthContext'
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import TableTopThreeView from './../Home/TableTopThree'
import { useStyles } from './styles';
import Api from 'services/Api';
import Image from 'react-image-webp';


import CoinEffect from 'assets/img/home/coin_effect.png';
import CoinEffectWepb from 'assets/imgWebp/coin_effect.webp';

let timeoutID = null;
const Home = () => {
	const {t} = useTranslation()
	const classes = useStyles()
	const authContent = useContext(AuthContext);
	const [dataMap, setDataMap] = useState({
		timeNextDraw: {},
		prizeList: [],
		singlePrizes: [],
		prizeListFirst: [],
		prizeListSecond: []
	})
	
	const handleTimeoutReloadPage = (time)=> {
		if (timeoutID) {
			clearTimeout(timeoutID)
		}
		if (time && time > 0) {
			timeoutID = setTimeout(() => {
				window.location.reload();
			}, time * 1000);
		}
	}

	useEffect(()=> {
		Api.requestHomePage().then((res) => {
			const isSuccess = get(res, 'data.success', false);
			if (isSuccess) {
				const data = get(res, 'data.data', {});
				authContent.dataList = data;
				const hData = authContent.mapDataHomePage(data)
				setDataMap(hData)
				handleTimeoutReloadPage(hData.timeNextDraw.time_until_next_reload_seconds);
			}
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authContent, t])

	return(
		<div className={classes.container_page} style={{ animation: 'fadeIn ease-in .3s' }}>
			<Image alt='logo' src={CoinEffect} webp={CoinEffectWepb} className={classes.img_coin_effect} />
			<TableTopThreeView data={dataMap.singlePrizes[0] ? dataMap.singlePrizes[0].numbers : []} period={dataMap.timeNextDraw} />
			<br />
		</div>
	)
};

export default Home
