import React from 'react';
import Image from 'react-image-webp';
import { useStyles } from './styles'

import Logo from 'assets/img/home/logo.png';
import LogoWepb from 'assets/imgWebp/logo.webp';

import lotto from 'assets/img/home/lotto.png';
import lottoWepb from 'assets/imgWebp/lotto.webp';

const LogoCompany = () => {
  const classes = useStyles();
  
  return (
      <div className={classes.containner}>
        <Image alt='logo' src={Logo} webp={LogoWepb} className={classes.img_logo} />
        <Image alt='logo' src={lotto} webp={lottoWepb} className={classes.img_lottery} />
      </div>
  )
};

export default LogoCompany
