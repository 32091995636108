import React from 'react';
import { useStyles } from './styles';
import Image from 'react-image-webp';
import { useTranslation } from 'react-i18next';
import ball0 from 'assets/img/home/ball_0.png';
import ball0Wepb from 'assets/imgWebp/ball_0.webp';
import ball1 from 'assets/img/home/ball_1.png';
import ball1Wepb from 'assets/imgWebp/ball_1.webp';
import ball2 from 'assets/img/home/ball_2.png';
import ball2Wepb from 'assets/imgWebp/ball_2.webp';
import ball3 from 'assets/img/home/ball_3.png';
import ball3Wepb from 'assets/imgWebp/ball_3.webp';
import ball4 from 'assets/img/home/ball_4.png';
import ball4Wepb from 'assets/imgWebp/ball_4.webp';
import ball5 from 'assets/img/home/ball_5.png';
import ball5Wepb from 'assets/imgWebp/ball_5.webp';

const dataImage = [
	{
		url: ball0,
		webUrl: ball0Wepb,
	},
	{
		url: ball1,
		webUrl: ball1Wepb,
	},
	{
		url: ball2,
		webUrl: ball2Wepb,
	},
	{
		url: ball3,
		webUrl: ball3Wepb,
	},
	{
		url: ball4,
		webUrl: ball4Wepb,
	},
	{
		url: ball5,
		webUrl: ball5Wepb,
	}
]

const TableTopThree = ({ data = [], period = {} }) => {
	const classes = useStyles()
	const { t } = useTranslation()
	const renderTitle = () => {
		if (!period.result_time || period.result_time === '') {
			return (
				<div className={classes.winner_title}>
					<span>
						{t('WinNumbers')}
					</span>
				</div>
			)
		} else {
			return (
				<>
					<div className={classes.winner_title}>
						<span>
							{t('NextDraw')}
						</span>
						<pre className={classes.sub_title}>
							{t('WillBeHelp')}
						</pre>
					</div>
				</>
			)
		}
	}

	const renderToday = () => {
		if (!period.result_time || period.result_time === '') {
			return (
				<div className={classes.winner_time}>
					<pre>
						{period.day_of_week}
					</pre>
					<pre>
						{period.date}
					</pre>
				</div>
			)
		} else {
			return (
				<div className={classes.winner_time}>
					<pre>
						{period.day_of_week}
					</pre>
					<pre>
						{period.date}
					</pre>
					<span className={classes.result_time}>
						{t('At')} {period.result_time}
					</span>
				</div>
			)
		}
	}

	const renderResultToday = () => {
		if (!period.result_time || period.result_time === '') {
			const arrTopData = data.slice(0, data.length - 2)
			const arrBottomData = data.slice(data.length - 2, data.length)
			let indexBall = 0;
			return<>
				<div className={classes.group_ball_row}>
				{
					arrTopData.map((item, index) => {
						const image = dataImage[indexBall]
						indexBall++;
						return <div key={index} className={classes.winners__table}>
							<Image alt='ball result' src={image.url} webp={image.webUrl} className={classes.img_logo} />
							<span className={classes.number_content}>{item}</span>
						</div>
					})
				}
				</div>
				<div className={classes.group_ball_row}>
				{
					arrBottomData.map((item, index) => {
						const image = dataImage[indexBall]
						indexBall++;
						return <div key={index} className={classes.winners__table}>
							<Image alt='ball result' src={image.url} webp={image.webUrl} className={classes.img_logo} />
							<span className={classes.number_content}>{item}</span>
						</div>
					})
				}
				</div>
			</>
		} else {
			const arrTopData = dataImage.slice(0, dataImage.length - 2)
			const arrBottomData = dataImage.slice(dataImage.length - 2, dataImage.length)
			return <>
				<div className={classes.group_ball_row}>
				{
					arrTopData.map((item, index) => {
						return <div key={index} className={classes.winners__table}>
							<Image alt='ball result' src={item.url} webp={item.webUrl} className={classes.img_logo} />
							<span className={classes.number_content}>?</span>
					</div>
				})
				}
				</div>
				<div className={classes.group_ball_row}>
				{
					arrBottomData.map((item, index) => {
						return <div key={index} className={classes.winners__table}>
							<Image alt='ball result' src={item.url} webp={item.webUrl} className={classes.img_logo} />
							<span className={classes.number_content}>?</span>
					</div>
				})
				}
				</div>
			</>
		}
	}

	return <div className={classes.winners}>
		{renderTitle()}
		<div className={classes.winners__table__wrapper}>
			{renderToday()}
			<div className={classes.group_ball_image}>
				{renderResultToday()}
			</div>
		</div>
	</div>
};

export default TableTopThree;
