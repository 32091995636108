import React, { useState, useEffect} from "react";
import { get, map } from 'lodash';
import { useTranslation } from 'react-i18next';
import Api from 'services/Api';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


import TableWinners from './../Home/TableWinners'
import { useStyles } from './styles';
import { Button } from "@material-ui/core";
const dateTime = new Date()
const currentYear = dateTime.getFullYear()
const currentMonth = dateTime.getMonth()
const yearList = [currentYear, currentYear - 1, currentYear - 2, currentYear - 3, currentYear - 4, currentYear - 5, currentYear - 6, currentYear - 7];
const monthList = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];

const Results = () => {
	const [openYear, setOpenYear] = useState(false);
	const [yearSelect, setYearSelect] = useState(currentYear);
	const [openMonth, setOpenMonth] = useState(false);
	const [monthSelect, setMonthSelect] = useState(monthList[currentMonth]);
	const [dataMap, setDataMap] = useState([])

	const classes = useStyles();
	const { t } = useTranslation();

	useEffect(() => {
		handleClick();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	},  [t])
	
	const handleOpenDropdownMonth = () => {
		setOpenMonth(true)
	}

	const handleCloseDropdownMonth = () => {
		setOpenMonth(false)
	}

	const handleChangeDropdownMonth = (event) => {
		const month = event.target.value;
		if (month !== monthSelect) {
			setMonthSelect(month)
		}
	};

	const handleOpenDropdownYear = () => {
		setOpenYear(true)
	}

	const handleCloseDropdownYear = () => {
		setOpenYear(false)
	}

	const handleChangeDropdownYear = (event) => {
		const year = event.target.value;
		if (year !== yearSelect) {
			setYearSelect(year);
		}
	};

	const handleClick = ()=> {
		const params = {
			month: `${monthSelect}`,
			year: `${yearSelect}`
		}

		Api.requestPageResult(params).then((res) => {
			const isSuccess = get(res, 'data.success', false);
			if (!isSuccess) {
				// can not get data
				//render maintainer page
			} else {
				const data = get(res, 'data.data', []);
				setDataMap(data);
			}
		});
	}

	return (
		<div className={classes.container_page} style={{ animation: 'fadeIn ease-in .1s' }}>
			<div className={classes.container}>
			<div className={classes.result_container_header}>
					<FormControl className={classes.formControl} hiddenLabel>
						<Select
							labelId="demo-controlled-open-select-label"
							id="demo-controlled-open-select"
							open={openMonth}
							disableUnderline
							onClose={handleCloseDropdownMonth}
							onOpen={handleOpenDropdownMonth}
							value={monthSelect}
							onChange={handleChangeDropdownMonth}
						>
							{
								map(monthList, (item, index) => <MenuItem key={index} value={item}>{t(item)}</MenuItem>)
							}
						</Select>
					</FormControl>
					<FormControl className={classes.formControl} hiddenLabel>
						<Select
							labelId="demo-controlled-open-select-label"
							id="demo-controlled-open-select"
							open={openYear}
							disableUnderline 
							className={classes.select___item}
							onClose={handleCloseDropdownYear}
							onOpen={handleOpenDropdownYear}
							value={yearSelect}
							onChange={handleChangeDropdownYear}
						>
							{
								map(yearList, (item, index) => <MenuItem key={index} value={item}>{item}</MenuItem>)
							}
						</Select>
					</FormControl>
					<Button className={classes.button__submit} onClick={handleClick}>
						{t('SUBMIT')}
					</Button>
				</div>
				<TableWinners data={dataMap} />
			</div>
		</div>
	)
};

export default Results
