import { makeStyles } from "@material-ui/core/styles";
import { color, font } from "config/styles";

export const useStyles = makeStyles((theme) => ({
	container_page: {
		display: 'flex',
		flex: '1',
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			backgroundColor: color.mainBackground
		}
	},
	container: {
		width: '100%',
		position: 'relative',
		margin: '0 10px 0 10px',
		backgroundColor: 'white',
		[theme.breakpoints.down('sm')]: {
			margin: '0',
		}
	},

	result_container_header: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'row',
		padding: '20px 0 10px 0',
		[theme.breakpoints.down('sm')]: {
			padding: '20px 10px 10px 10px',
		}
	},
	result_title: {
		display: 'flex',
		alignItems: 'flex-end',
		'& span': {
			fontSize: font.largeSize,
			textTransform: 'uppercase',
			fontFamily: font.boldFamily,
			[theme.breakpoints.down('sm')]: {
				fontSize: font.largeSize__mobile,
			}
		},
		[theme.breakpoints.down('sm')]: {
			// marginTop: '10px'
		}
	},
	content: {
		display: 'flex',
		margin: '2px',
		borderRadius: '6px',
		fontSize: font.nomalSize,
		background: 'linear-gradient(to right, #2963eb, #e04ad0 )',
		color: '#efeef3',
		'& span': {
			backgroundColor: color.tableBackground,
			margin: '2px',
			borderRadius: '6px',
			textAlign: 'left',
		}
	},
	result_container_content: {
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'space-between',
		flexDirection: 'row',
	},
	result_content: {
		display: 'flex',
		width: '100%',
		margin: '5px',
		justifyContent: 'flex-start',
		alignItems: 'center',
		flexDirection: 'column',
		[theme.breakpoints.down('sm')]: {
			margin: '10px'
		}
	},
	select___item: {
		
	},
	formControl: {
		minWidth: '60px',
		// maxWidth: '180px',
		maxHeight: '40px',
		minHeight: '30px',
		height: '40px',
		width: '220px',
		marginRight: '10px',
		border: `1px solid #1ab08d`,
		justifyContent: 'center',
		alignItems: 'space-between',
		borderRadius: '3px',
		color: '#000',
		[theme.breakpoints.down('sm')]: {
			fontSize: '18px',
			width: '200px',
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '8.5px',
			width:'180px',
			height: '35px',
			minHeight: '25px',
		},
		// textAlign: 'center',
		'& div': {
			color: '#000',
			paddingLeft: '5px',
			fontSize: font.nomalSize,
			fontFamily: 'SegoeBold',
			[theme.breakpoints.down('sm')]: {
				fontSize: '18px',
			},
			[theme.breakpoints.down('xs')]: {
				fontSize: '15px',
			}
		},

		'& :after': {
			display: 'none'
		},

		'& svg': {
			color: 'black',
			right: '0px'
		}
	},
	slideContainer: {
		display: 'flex'
	},
	button__submit: {
		width: '180px',
		height: '40px',
		fontSize: '18px',
		backgroundImage: 'linear-gradient(to right, #1ab08d , #87bf4d)',
		color: 'white',
		fontFamily: font.boldFamily,
		[theme.breakpoints.down('sm')]: {
			width: '180px',
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '14px',
			width:'30vw',
			height: '35px',
			minHeight: '25px',
		},
		'& span': {
			color: 'white'
		}
	}
}));
