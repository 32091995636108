import { makeStyles } from "@material-ui/core/styles";

import { color } from "config/styles";

export const useStyles = makeStyles((theme) => ({
  menu__list: {
    display: 'flex',
    backgroundColor: '#c8e5d3',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 0 0px',
    }
  },
  menu__routers: {
    display: 'flex',
    padding: '10px 20px 10px 20px',
    alignItems: 'center',
    justifyContent:'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5vw',
      width: '100%',
      height: '40px',
      padding: '0px 0px 0px 0px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.5vw',
      width: '100%',
      height: '30px',
      padding: '0px 0px 0px 0px',
    }
  },
  menu__item_language: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginRight: '45px',
    color: color.black,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginRight: '0px',
      width: '34%',
      fontSize: '2.0vw',
      height: '100%',
      color: 'black',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginRight: '0px',
      width: '34%',
      fontSize: '2.5vw',
      height: '100%',
      color: 'black',
    }
  },

  menu__item: {
    marginRight: '45px',
    color: color.black,
    fontFamily: 'Tahoma',
    fontSize: '20px',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: '0px',
      width: '34%',
      fontSize: '2.2vw',
      height: '100%',
      color: 'black',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: '0px',
      width: '34%',
      fontSize: '3.0vw',
      height: '100%',
      color: 'black',
    }
  },

  menu__item_selected: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    color: color.black,
    fontFamily: 'TahomaBold',
    '& .after': {
      display: 'none',
      border: `1px solid #43ae66`,
      borderRadius: '5px',
      backgroundColor: '#43ae66',
      width: '140%',
      height: '3px',
      marginTop: '2px',
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      alignItems: 'flex-start',
      color: '#fff',
      marginRight: '0px',
      // width: '100%',
      height: '100%',
      '& .after': {
        display: 'flex',
        border: `1px solid #43ae66`,
        backgroundColor: '#43ae66',
      }
    }
  },

  menu__item_link: {
    display: 'flex',
    color: color.black,
    [theme.breakpoints.down('sm')]: {
      color: 'black',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: '0px',
      // width: '100%',
      height: '100%',
    }
  }
}));
