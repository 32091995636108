import { makeStyles } from "@material-ui/core/styles";
import { font } from "config/styles";

export const useStyles = makeStyles((theme) => ({
  winners: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    justifyContent:'center',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '15px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '10px',
      maxWidth: '1560px',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '1560px',
      marginBottom: '5px',
      alignItems: 'center',
    }
  },
  
  winner_title: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    padding: '10px',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      width: '100%'
    },
    '& span': {
      display: 'inline-block',
      margin: '0px',
      color: '#f2b900',
      backgroundColor: '#f2b900',
      textTransform: 'uppercase',
      fontFamily: font.boldFamily,
      fontSize: '38px',
      background: 'linear-gradient(#f2b900, #c96f00)',
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
      '-webkit-text-stroke': '1px black',
      [theme.breakpoints.down('sm')]: {
        fontSize: '35px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '30px',
      },
    }
  },

  sub_title: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    margin: '0px',
    color: 'black',
    textTransform: 'uppercase',
    fontFamily: 'none',
    fontSize: font.nomalSize,
    [theme.breakpoints.down('sm')]: {
      fontSize: '20',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
  result_time: {
    color: 'red'
  },
  winner_time: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    flexDirection: 'column',
    // width: '40%',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      alignItems: 'center',
      padding: '10px'
    },
    padding: '5px',
    '& span, pre': {
      letterSpacing: '0px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'right',
      margin: '0px',
      paddingRight: '20px',
      color: '#3d3939',
      fontFamily: font.boldFamily,
      fontSize: '22px',
      borderRight: "3px solid #35343A",
      [theme.breakpoints.down('sm')]: {
        fontSize: '22px',
        borderRight: "0px solid #35343A",
      },
      [theme.breakpoints.down('xs')]: {
        // fontSize: '4.2vw',
        fontSize: '22px',
        borderRight: "0px solid #35343A",
      },
    },
    '& span': {
      // color: 'red' 
    }
  },
  
  winners__table__wrapper: {
    display: 'flex',
    width: '100%',
    borderRadius: '5px',
    padding: '10px',
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      flexDirection: 'column',
      flexWrap: 'wrap',
      borderRadius: '0px',
      fontSize: font.nomalSize__mobile,
    },
  },

  group_ball_image: {
    display: 'flex',
    margin: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },

  group_ball_row: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },

  number_content: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'right',
    margin: '0px',
    color: '#3d3939',
    left: '0',
    right: '0',
    bottom: '32%',
    textTransform: 'uppercase',
    fontFamily: font.boldFamily,
    fontSize: '28px',
    [theme.breakpoints.down('sm')]: {
      // fontSize: font.nomalSize__mobile,
    },
    [theme.breakpoints.down('xs')]: {
      // fontSize: '4.2vw',
    },
  },
  winners__table: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  img_logo: {
    width: '80px',
    height: 'auto'
  },
  
  contanner__image: {
    display: 'flex',
    position: 'relative',
    height: '100%',
    width: '80px',
    [theme.breakpoints.down('sm')]: {
      width: '60px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '50px',
    },
  },

  image_title: {
    position: 'absolute',
    bottom: '0px',
    left: '0px',
    height: '60px',
    width: 'auto',
    margin:'2px 2px 0px 2px',
    [theme.breakpoints.down('sm')]: {
      margin:'2px 2px 0px 2px',
      height: '60px',
    },
    [theme.breakpoints.down('xs')]: {
      margin:'2px 2px 0px 2px',
      height: '50px',
    }
  },
}));
