import React from "react";
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

const AboutUs = () => {
  const classes = useStyles()
  const {t} = useTranslation();
  
  return (
    <div className={classes.container_page} style={{animation: 'fadeIn ease-in .1s'}}>
      <div className={classes.content}>
        <span>
          <div className={classes.content_group}>
            <pre className={classes.content_center}>
              {t('ContactUsContent')}
            </pre>
          </div>
        </span>
      </div>
      <br />
    </div>
  )
};

export default AboutUs
