import { makeStyles } from "@material-ui/core/styles";
import { layout, font } from "config/styles";

export const useStyles = makeStyles((theme) => ({
	footer: {
		display: 'flex',
		width: '100%',
		justifyContent: 'center',
		flexDirection: 'column',
		height: layout.footerHeight,
		backgroundColor: '#d0d0d0',
		[theme.breakpoints.down('sm')]: {
			height: '70px',
			// padding: '1vw 0',
		},
		[theme.breakpoints.down('xs')]: {
			height: '50px',
			// padding: '1vw 0',
		}
	},
	footerClass: {
		display: 'flex',
		width: "100%",
		height: "100%",
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		textAlign: 'center',
	},

	footerLeft: {
		width: "100%",
		display: 'flex',
		alignItems: "center",
		justifyContent: 'center'
	},
	footerTextLeft: {
		display: 'flex',
		alignItems: "center",
		justifyContent: 'center',
		flexDirection: 'row',
		flexWrap: 'wrap',
		fontSize: font.smallSize,
		margin: '0px 20px 0 20px',
		[theme.breakpoints.down('sm')]: {
			fontSize: font.smallSize__mobile,
		}
	},

	footerRight: {
		width: "100%",
		display: 'flex',
		alignItems: "center",
		justifyContent: 'center',
		fontSize: font.smallSize,
		color: 'black',
		[theme.breakpoints.down('sm')]: {
			fontSize: '2vw',
			alignItems: "center",
		},
		'& span': {
			whiteSpace: 'pre-line',
			fontSize: '12px',
			[theme.breakpoints.down('sm')]: {
				fontSize: '1.4vw',
			},
		}
	},
	
	fotter_containner_top: {
		display: 'flex',
		width: '100%',
		height: '100%'
	},
}));
